var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,class:item.current ? 'current' : '',attrs:{"to":{
      name: 'AttendanceCheck',
      params: {
        course: item.course.id,
        date: item.date,
        period: item.periods[0].id,
      },
    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.course.title)+" ")]),_c('v-list-item-subtitle',[_c('DateValue',{attrs:{"value":item.date,"long":""}}),_vm._v(", "+_vm._s(_vm.formatTimespan(item.date, item.startTime, item.date, item.endTime))+" ")],1)],1),_c('v-list-item-avatar',[_c('AttendanceCheckStatus',{attrs:{"value":item}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }