var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.items,"items-per-page":-1,"sort-by":"date","sort-desc":true,"must-sort":""},on:{"click:row":(item) =>
      _vm.$router.push({
        name: 'AttendanceCheck',
        params: {
          course: item.course.id,
          date: item.date,
          period: item.periods[0].id,
        },
      })},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_c('DateValue',{attrs:{"value":item.date}})]}},{key:"item.time",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatTimespan(item.date, item.startTime, item.date, item.endTime))+" ")]}},{key:"item.periods",fn:function({ item }){return [_vm._v(" "+_vm._s(item.periods.length)+" ")]}},{key:"item.absences",fn:function({ item }){return _vm._l((_vm.sortPeople(item.absences)),function(absence){return _c('PersonItem',{key:'absence' + absence.id,attrs:{"small":"","value":absence}})})}},{key:"item.status",fn:function({ item }){return [_c('AttendanceCheckStatus',{attrs:{"value":item}})]}},{key:"footer",fn:function(){return [_c('TableFooter',{attrs:{"items":_vm.items,"label":"Kontrollen","labelSingular":"Kontrolle"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }