<template>
  <div>
    <v-tooltip v-if="value.signed && value.checked" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="success"
          >mdi-checkbox-marked-outline</v-icon
        >
      </template>
      <span>erledigt</span>
    </v-tooltip>
    <v-tooltip v-else-if="value.signed && !value.checked" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="success"
          >mdi-checkbox-blank-off-outline</v-icon
        >
      </template>
      <span>nicht durchführbar</span>
    </v-tooltip>
    <v-tooltip v-else-if="value.checkable" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="error"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <span>zu erledigen</span>
    </v-tooltip>
    <v-tooltip v-else top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="error"
          >mdi-checkbox-blank-off-outline</v-icon
        >
      </template>
      <span>verpasst</span>
    </v-tooltip>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["value"],
});
</script>
